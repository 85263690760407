import { Link } from 'react-router-dom';
export const Thanks = (props) => {
    return (
        <div className='text-center' style={{marginTop:'80px',display:'table'}}>
        <div className="container">
            <h1>Thanks For Submitting the form</h1>
            <h1>Something Went Wrong</h1>
            <h4>“our Call Back Request has been submitted , you will get a get from our team within 5-10 min. Thanks for your patience”</h4>
            <Link to="/" className='btn' style={{color:'red'}}><b><u>Go Home</u></b></Link>
        </div>
        </div>
    )
}

import React, { useState } from 'react';


const Footerdetail = (props) => {
  return (
    <>
      <div id='footerdetail' >
        <div id='callus'>
          <div className="container">
           
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <h3>Do you need the RO Maintenance Services?</h3>
                <br />
                <a className="btn-right" href="/#contact">Book A Free Assessment</a><br />
                <div class="flex-container">
                  <div className='facebook'>
                    <a title="share with facebook " className="facebook pop-upper without-counter" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fro-water-purifier-services.in%2F&amp;t=rowaterpurifierservice" target="_blank" rel="noopener noreferrer" >
                    <i class="fa fa-facebook"></i>
                      
                    </a>
                  </div>
                  <div className='linkedin'>
                    <a title="share with: linkedin" className="linkedin pop-upper without-counter" href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fro-water-purifier-services.in%2F&amp;title=rowaterpurifierservice&amp;summary=&amp;source=" target="_blank" rel="noopener noreferrer" style={{ width: "16.6667%" }}>
                    <i class="fa fa-linkedin"></i>
                    </a>
                  </div>
                  <div className='instagram'>
                    <a title="share with: Instagram " className="instagram pop-upper without-counter" href="https://www.instagram.com//?utm_source=instagram_story&utm_medium=story_link&utm_campaign=${https://ro-water-purifier-services.in}" target="_blank" rel="noopener noreferrer" style={{ width: "16.6667%" }}>
                    <i class="fa fa-instagram"></i>
                    </a>
                  </div>
                  <div className='twitter'>
                    <a title="tweet " className="twitter pop-upper without-counter" href="https://twitter.com/intent/tweet?text=rowaterpurifierservice%20https%3A%2F%2Fro-water-purifier-services.in%2F" target="_blank" rel="noopener noreferrer" style={{ width: "16.6667%" }}>
                    <i class="fa fa-twitter"></i>
                    </a>
                  </div>
                  <div className='whatsapp'>
                    <a title="share with: whatsapp" className="whatsapp pop-upper without-counter" href="https://wa.me/?text=https://ro-water-purifier-services.in." target="_blank" rel="noopener noreferrer" style={{ width: "16.6667%" }}>
                    <i class="fa fa-whatsapp"></i>
                    </a>
                  </div>
                  <div className='telegram'>
                    <a title="share with: telegram" className="telegram pop-upper without-counter" href="https://t.me/share/url?url=https://ro-water-purifier-services.in&text=Top RO Water Purifier Repair Service in your City @ 7762998962" target="_blank" rel="noopener noreferrer" style={{ width: "16.6667%" }}>
                    <i class="fa fa-telegram"></i>
                    </a>
                  </div>
                  <div className='pinterest'>
                    <a title="share with: pinterest" className="pinterest pop-upper without-counter" href="https://www.pinterest.com/pin/create/button/?url=https%3A%2F%2Fro-water-purifier-services.in%2F&amp;description=&amp;media=" target="_blank" rel="noopener noreferrer" style={{ width: "16.6667%" }}>
                    <i class="fa fa-pinterest"></i>
                    </a>
                  </div>
                  
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 contact-info">
                <div className='contact-item'>
                  <h3>Contact Info</h3>
                </div>
                <div className='contact-item'>
                  <p>
                    <span>
                      <i className='fa fa-phone'></i> Phone:
                    </span>{' '}
                    <a href="tel: 18005710106">
                      {props.data ? props.data.phone : 'loading'}</a>
                  </p>
                </div>
                <div className='contact-item'>
                  <p>
                    <span>
                      <i className='fa fa-envelope-o'></i> Email:
                    </span>{' '}
                    <a href="mailto: contactrowaterpurifierservices@gmail.com">
                      {props.data ? props.data.email : 'loading'}</a>
                  </p>
                </div>
                {/*
                  <div className='contact-item'>
                  <p>
                    <span>
                      <i className='fa fa-whatsapp'></i> Whatsapp:
                    </span>{' '}
                    <a href="https://api.whatsapp.com/send?phone=919031405444" target="_blank" rel="noreferrer">
                      {props.data ? props.data.phone : 'loading'}</a>
                  </p>
                </div>
                  */}
                <div className='contact-item'>
                  <p>
                    <span>
                      <i className='fa fa-map-marker'></i> {props.data ? props.data.address : 'loading'}
                    </span>{' '}
                  </p>
                </div>
                <div className='contact-item'>
                  <h3>
                    <span>
                      Service Area - Delhi NCR and PAN India
                    </span>
                  </h3>
                </div>


              </div>


              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <h3>UseFul Links</h3>
                <div className="row">
                  <div className="col-xs-6 col-md-6">
                    <ul className='contact-item'>
                      <li>
                        <a href='#contact' className='page-scroll'>
                          Contact
                        </a>
                      </li>
                      <li>
                        <a href='#features' className='page-scroll'>
                          What We Do
                        </a>
                      </li>
                      <li>
                        <a href='#about' className='page-scroll'>
                          About
                        </a>
                      </li>
                      <li>
                        <a href='#pricetag' className='page-scroll'>
                          Pricing
                        </a>
                      </li>
                      <li>
                        <a href='#services' className='page-scroll'>
                          Services
                        </a>
                      </li>
                      <li>
                        <a href='#testimonials' className='page-scroll'>
                          Testimonials
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xs-6 col-md-6">

                    <ul className='contact-item'>
                      <li>
                        <a href='/return-policy'>
                          Return Policies
                        </a>
                      </li>
                      <li>
                        <a href='/privacy-policy' >
                          Privicy Policies
                        </a>
                      </li>
                      <li>
                        <a href='/terms-condition'>
                          Terms & Condition
                        </a>
                      </li>
                      <li>
                        <a href='/faq'>
                          FAQs
                        </a>
                      </li>
                      <li>
                        <a href='/help-center'>
                          Help Center
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

      </div>
    </>
  )
}

export default Footerdetail;
import React from 'react';
import { Carousel } from 'react-responsive-carousel';

const Crous2 = () => (
    <Carousel autoPlay infiniteLoop >
        <div>
            <img alt="" src="https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60" />
        </div>
        <div>
            <img alt="" src="https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60" />
        </div>
        <div>
            <img alt="" src="https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80"/>
        </div>
        
        
    </Carousel>
);
export default Crous2;
import { useState, useEffect } from "react";
import { Route, Routes } from 'react-router-dom';
import JsonData from "./data/data.json";

import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Navigation } from "./components/navigation";
import { Footer } from "./components/footer";
import Footerdetail from "./components/Footerdetail";
import Home from "./Home"
import { Returnypolicy } from "./components/pages/returnpolicy";
import { TermsCondition } from "./components/pages/termscondition";
import { Faqp } from "./components/pages/faqp";
import { PageNotFound } from "./components/pageNotFound";
import { Privacypolicy } from "./components/pages/privacypolicy";
import { Helpcenter } from "./components/pages/helpcenter";
import { Thanks } from "./components/thanks";




export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
    <div className="social">
        
          <div className="pop">
          <a className="phonescreenstick" href="tel:18005710106">
            <img style={{ border: "0" }} src="img/rowatercall.png" alt="callus@18005710106" />
          </a>
        </div>
          
      </div>
      {/*
        <div className='social'>
        <div>
          <a className="whatsappscreen" href="https://api.whatsapp.com/send?phone=9118005710106" target="_blank" rel="noreferrer">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
        <div>
          <a className="phonescreen" href="tel:18005710106">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </div>
        */}
      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/return-policy" element={<Returnypolicy />} />
        <Route path="/help-center" element={<Helpcenter />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/faq" element={<Faqp />} />
        <Route path="/thanks" element={<Thanks />} />

        
      </Routes>
      <Footerdetail data={landingPageData.Contact} />
      <Footer data={landingPageData.Footer} />
    </div>

  );
};


export default App;
